import React, { FC, ReactChildren, ReactChild, ReactNode } from 'react';
import styled from 'styled-components';
import Container from '../elements/Container';
import { theme } from '../../theme';
interface HeaderWithImageProps {
  children?: ReactNode | ReactChild | ReactChildren;
  bgImage?: any;
  backgroundColor?: string;
  image?: string;
  bgThumbnail?: string;
  minHeight?: string;
  apiImageUrl?: boolean;
}
const BannerContent = styled.div<HeaderWithImageProps>`
  color: ${(props: any) => props.theme.colors.white};
  min-height:  ${(props: any) => props.minHeight ? props.minHeight : '80vh'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const BackgroundImage = styled.section<HeaderWithImageProps>`
  width:100%;
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  ::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props: any) => (props.theme.colors.black)};
    opacity: 0.5;
  }
  //background:url(${props => props.bgImage}),url(${props => props.bgThumbnail}), ${(props: any) => props.theme.colors.pantoneBlue};
  
  `;
const HeaderWithImage: FC<HeaderWithImageProps> = ({ children, image, bgThumbnail, minHeight, bgImage, apiImageUrl, ...props }) => {
  return (
    <>
      {/* Added this code to check the issues on build*/}

      {/* <BackgroundImage bgImage={bgImage} bgThumbnail={bgThumbnail}> */}
      <BackgroundImage style={{
        background: `url(${bgImage}),url(${bgThumbnail}),${theme.colors.pantoneBlue}`
      }}>
        <Container>
          <BannerContent minHeight={minHeight} >
            {children}
          </BannerContent>
        </Container>
      </BackgroundImage>
    </>

  );
};

export default HeaderWithImage;