import React, { FC } from 'react';
import { Flex } from 'rebass';
import { DescriptionText } from "./Typography";
interface DescriptionProps {
  textColor: string;
  text: string;
  boldText?: string;
  boldTextColor?: string;
  fontFamily?: string;
  fontSize?: string;
  textFontWeight?: number | string;
  textAlign?: string;
  lineHeight?: number;
  margin?: string;
  maxWidth?: string;
}
const Description: FC<DescriptionProps> = ({ margin, textAlign, lineHeight, text, textColor, maxWidth, textFontWeight, fontFamily, fontSize }) => {
  return (
    <Flex alignItems='center'>
      <DescriptionText
        textAlignMob={'center'}
        margin={margin}
        fontFamily={fontFamily}
        textAlign={textAlign}
        lineHeight={lineHeight}
        fontWeight={textFontWeight}
        textColor={textColor}
        maxWidth={maxWidth}
        fontSize={fontSize}>
        {text}
      </DescriptionText>
    </Flex>
  );
};

export default Description;
