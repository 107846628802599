import styled from "styled-components";
import { down } from 'styled-breakpoints';

const Container = styled.div`
  padding: 0 ${props => props.theme.spacing[2]}rem;
  max-width: 1280px;
  margin: 0 auto;
  ${down('md')}{
  padding: 0 15px;
  }
`;

export default Container;